<template>
	<div>
		<Navbar/>
		<div :style="{'background-image': `url(${require('../assets/img/cover2.jpg')})`, 'background-size': `cover`, 'background-position': 'center'}" class="pt-12">
			<div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8" >
				<div class="lg:text-center mb-8">
					<p class="text-base leading-6 text-hydro font-semibold tracking-wide uppercase"> {{ $t('about.title') }} </p>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
						{{ $t('about.headline') }}
					</h3>
					<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-700 lg:mx-auto">
						{{ $t('about.headline2') }}
					</p>
				</div>
			</div>
		</div>
		<div class="lg:text-center mt-12 mx-4">
			<h3 class="mt-2 text-xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-10">
				{{ $t('about.feature1') }}
			</h3>
			<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
				{{ $t('about.feature2') }}
			</p>
			<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
				{{ $t('about.feature3') }}
			</p>
		</div>
		<div class="py-12 bg-white">
			<div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
				<div class="lg:text-center">
					<p class="text-base leading-6 text-hydro font-semibold tracking-wide uppercase"> {{ $t('about.tech_title') }} </p>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
						{{ $t('about.tech_headline') }}
					</h3>
					<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
						{{ $t('about.tech_headline2') }}
					</p>
				</div>
				<div class="mt-10">
					<ul class="md:grid md:grid-cols-3 md:col-gap-8 md:row-gap-10">
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/docker.svg" class="m-auto h-20" alt="chat">
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/proxmox.png" class="m-auto h-20" alt="chat">
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/ansible.png" class="m-auto h-20" alt="chat">
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/vesta.png" class="m-auto h-20" alt="chat">
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/sonar.png" class="m-auto h-20" alt="chat">
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/ubuntu.png" class="m-auto h-20" alt="chat">
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/arbor.png" class="m-auto h-20" alt="chat">
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/jenkins.png" class="m-auto h-20" alt="chat">
							</div>
						</li>
						<li>
							<div class="flex-shrink-0">
								<img src="../assets/img/pterodactyl.png" class="m-auto h-20" alt="chat">
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="bg-gray-50 py-12">
			<div class="lg:text-center mb-8">
				<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
					{{ $t('about.follow') }}
				</h3>
			</div>
			<ul class="md:grid md:grid-cols-4">
				<li>
					<div class="flex-shrink-0">
						<a href="https://twitter.com/hydrosaas" target="_blank"><img src="../assets/img/twitter.svg" class="m-auto h-20" alt="chat"></a>
					</div>
				</li>
				<li>
					<div class="flex-shrink-0">
						<a href="https://www.facebook.com/Hydrosaas-109706434050464" target="_blank"><img src="../assets/img/facebook.png" class="m-auto h-20" alt="chat"></a>
					</div>
				</li>
				<li>
					<div class="flex-shrink-0">
						<a href="https://www.linkedin.com/company/hydrosaas/" target="_blank"><img src="../assets/img/linkedin.png" class="m-auto h-20" alt="chat"></a>
					</div>
				</li>
				<li>
					<div class="flex-shrink-0">
						<a href="https://mastodon.hydrosaas.com/@hydrosaas" target="_blank"><img src="../assets/img/mastodon.png" class="m-auto h-20" alt="chat"></a>
					</div>
				</li>
			</ul>
		</div>
		<div class="bg-gray-50">
			<Footer />
		</div>
	</div>
</template>

<script>
	import Navbar from "@/components/Navbar";
	import Footer from "@/components/Footer";
	export default {
		name: 'About',
      metaInfo: () => ({
        title: 'HydroSaas',
        titleTemplate: '%s | À Propos',
        meta: [
          { name: 'description', content: 'Hébergeur web de qualité, fournisseur d\'infraastructure cloud (VPS) à haute disponibilité et VPN. L\'équipe de HydroSaaS met ses compétences au service de votre projet.' },
          { name: 'og:title', content: 'À Propos de HydroSaaS : Hébergeur web, cloud VPS et VPN' },
          { name: 'twitter:title', content: 'À Propos de HydroSaaS' },
          { name: 'og:description', content: 'Hébergeur web de qualité, fournisseur d\'infraastructure cloud (VPS) à haute disponibilité et VPN. L\'équipe de HydroSaaS met ses compétences au service de votre projet.' },
          { name: 'twitter:description', content: 'Hébergeur web de qualité, fournisseur d\'infraastructure cloud (VPS) à haute disponibilité et VPN. L\'équipe de HydroSaaS met ses compétences au service de votre projet.' },
          { name: 'twitter:image', content: 'https://hydrosaas.com/default.png' },
          { name: 'og:image', content: 'https://hydrosaas.com/default.png' },
        ],
      }),
      htmlAttrs: {
        amp: true
      },
		components: {
			Footer,
			Navbar,
		},
	}
</script>
